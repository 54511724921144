import React from 'react'
// @src imports
import { Icon as LegacyIcon } from 'src/chrome'
import { defaultFragment } from 'src/editor/text/ElementText'
// relative imports
import styles from './editorToolBar.module.scss'
import { Api, ToasterNotification } from '../../api'
import { Steps } from '../../types'
import { isCustomCard as getIsCustomCard } from '../../../redux/selectors/editor'
import {
  ConfirmDialog,
  Dialog,
  Div,
  Flex,
  Icon,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { useState } from 'src/hooks/useState'
import {
  BACKGROUND_COLOR_SETTING_OPEN_VALUE,
  IMAGE_SETTING_OPEN_VALUE,
  LAYOUT_SETTINGS_OPEN_VALUE,
} from 'src/editor/constants'
import { useActions } from 'src/hooks'
import removeUsersnap from 'src/helpers/removeUsersnap'
import { useFeatureAccess } from 'src/hooks/useFeatureAccess'
import { LockedFeatureModal } from 'src/LockedFeatureModal/LockedFeatureModal'
import { FullAccessFeatureType } from 'src/graphql/generated/graphql'

interface Props {
  api: Api
  showConfirmVisible: boolean
  setShowConfirmVisible: (showConfirmVisible: boolean) => void
  onBackToCatalog?: () => void
}

const EditorToolBar: React.FC<Props> = props => {
  const {
    api,
    showConfirmVisible,
    setShowConfirmVisible,
    onBackToCatalog,
  } = props
  const {
    step,
    currentLayout,
    updatePanel,
    handleSave,
    card,
    fullBleeds,
    updateLayout,
    isCreatingBackPanel,
    setIsCreatingBackPanel,
    isMobile,
    setEditorDuvetOpenValue,
    setToasterNotification,
    setIsSaving,
  } = api

  const featureAccessArray = [FullAccessFeatureType.CardCustomBacks]
  const actions = useActions()
  const { lazyFeatureAccess } = useFeatureAccess(featureAccessArray)

  const [showLockedPanelInfoDialog, setShowLockedPanelInfoDialog] = useState(
    false,
  )
  const { fullBleedIndex, panelIndex } = step

  // TODO: React Query Refactor returns potentially undefined data in types
  // Not sure if this is the behavior we want
  if (!card) throw Error('No card provided')

  const isCustomCard = getIsCustomCard({
    ...card,
    sendableCard: card.detailedSendableCard,
  })

  const isBackPanel =
    fullBleeds.length > 2 && fullBleedIndex === fullBleeds.length - 1

  const isPanelLocked =
    fullBleedIndex === 0 && panelIndex === 0 && !isCustomCard

  const isLastPanel = fullBleedIndex === fullBleeds.length - 1

  const isSaveLayoutVisible =
    isLastPanel && card.type !== 'POSTCARD' && card.type !== 'FLATCARD'

  const handleAddTextElement =
    fullBleeds[0].panels[0].elements.length < 2
      ? () =>
          updatePanel(0, 0, panel => ({
            ...panel,
            elements: panel.elements.concat([
              {
                __typename: 'Element',
                x: 0.1,
                y: 0.1,
                z: panel.elements.length + 1,
                rotation: 0,
                width: 0.5,
                height: 0.2,
                locked: false,
                text: [defaultFragment],
                image: null,
                sticker: null,
                draggable: null,
              },
            ]),
          }))
      : () => {}

  const editLayout = () => {
    if (isBackPanel) {
      const hasAccess = lazyFeatureAccess.enable()
      if (hasAccess) {
        api.editLayout(fullBleedIndex, panelIndex ?? 0)
        setIsCreatingBackPanel(!isCreatingBackPanel)
      }
    } else {
      api.editLayout(fullBleedIndex, panelIndex ?? 0)
    }

    if (isMobile) setEditorDuvetOpenValue(LAYOUT_SETTINGS_OPEN_VALUE)
  }
  const isActive = (activeCheck: Steps) => {
    return (
      <Div
        width="6px"
        height="6px"
        backgroundColor={
          step.type === activeCheck
            ? { swatch: 'primaryBrand', shade: 'base' }
            : 'transparent'
        }
        borderRadius="circle"
      />
    )
  }

  const iconColor = () => (isMobile ? 'inverseHeadingText' : 'primaryBodyText')
  const textColor = () => (isMobile ? 'inverseHeading' : 'primaryBody')

  const backPanelToasterNotification: ToasterNotification = {
    iconName: 'squareCheck',
    content: 'Saved Back Panel Layout',
    type: 'success',
  }

  const onClose = () => {
    removeUsersnap()
    if (onBackToCatalog) {
      onBackToCatalog()
    } else {
      actions.openCatalog()
    }
  }

  // You may not want to block the entire UI, this is just a placeholder
  // If needed add a logical check inside the container to render a modal or other UI
  // EG !lazyFeatureAccess.hasAccess && <BlockingUI/>
  if (!lazyFeatureAccess.hasAccess) {
    return (
      <LockedFeatureModal
        onClose={() => lazyFeatureAccess.disable()}
        actions={{
          mainAction: {
            execute: () => console.log('clicked main action'),
            title: 'Unlock This & More Features',
          },
          secondaryAction: {
            execute: () => lazyFeatureAccess.disable(),
            title: 'Not Now',
          },
        }}
        textContent={{
          title: 'Unlock Me!',
          subTitle: 'Custom Back Panels are a Full System Feature.',
          description:
            'Upgrade your account from a Basic account to a Monthly Membership or any of our other plans to access this great feature!',
        }}
        image={{
          url:
            'https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/locked-feature-modal/image-1.png',
          height: '100%',
          width: '100%',
          top: '38px',
          left: '0px',
        }}
        featureAccessToGrant={featureAccessArray}
      />
    )
  }

  return (
    <div style={{ backgroundColor: '#fff' }} className={styles.editorPanel}>
      <nav
        style={
          isMobile
            ? {
                backgroundColor: '#1b1b1d',
                borderRadius: '21px 21px 0 0',
                width: '100vw',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                height: '66px',
                bottom: 0,
                position: 'fixed',
                paddingTop: '4px',
                paddingRight: '50px',
              }
            : {}
        }
      >
        {!isMobile && (
          <div
            className={styles.editorPanelLogo}
            onClick={() => setShowConfirmVisible(!showConfirmVisible)}
          >
            <div className={styles.logo}>
              <LegacyIcon icon={'ARROWLEFT'} size={17} color={'#6f8394'} />
              <Text type="caption" style={{ fontSize: '.625rem' }}>
                Catalog
              </Text>
            </div>
          </div>
        )}
        {!isPanelLocked ? (
          <div className={styles.itemContainer}>
            {step.fullBleedIndex === 0 && isCustomCard && (
              <div
                className={`${styles.menu} ${
                  step.type === Steps.EditText ? styles.selected : ''
                }`}
                onClick={() => handleAddTextElement()}
                id={'editor_textbox_btn'}
              >
                <Icon
                  name="editorText"
                  size="small"
                  primaryColor={iconColor()}
                />
                <Text type="caption" content="Text" color={textColor()} />
                {isActive(Steps.EditText)}
              </div>
            )}
            <div
              id={'editor_templates_btn'}
              className={`${styles.menu} ${
                step.type === Steps.EditLayout ? styles.selected : ''
              }`}
              onClick={() => editLayout()}
            >
              <Icon name="layout" primaryColor={iconColor()} size="small" />
              <Text type="caption" content="layouts" color={textColor()} />
              {isActive(Steps.EditLayout)}
            </div>
            <div
              id={'editor_panel_background_btn'}
              className={`${styles.menu} ${
                step.type === Steps.EditBackgroundColor ? styles.selected : ''
              }`}
              onClick={() => {
                if (isBackPanel) {
                  const hasAccess = lazyFeatureAccess.enable()

                  if (hasAccess) {
                    api.editBackgroundColor(fullBleedIndex, panelIndex ?? 0)
                    if (isMobile)
                      setEditorDuvetOpenValue(
                        BACKGROUND_COLOR_SETTING_OPEN_VALUE,
                      )
                  }
                } else {
                  api.editBackgroundColor(fullBleedIndex, panelIndex ?? 0)
                  if (isMobile)
                    setEditorDuvetOpenValue(BACKGROUND_COLOR_SETTING_OPEN_VALUE)
                }
              }}
            >
              <Icon name="background" primaryColor={iconColor()} size="small" />
              <Text type="caption" content="background" color={textColor()} />
              {isActive(Steps.EditBackgroundColor)}
            </div>

            {(step.type === Steps.EditPicture ||
              step.type === Steps.EditBackgroundPicture) && (
              <>
                <div
                  className={`${styles.menu} ${styles.selected}`}
                  onClick={() =>
                    setEditorDuvetOpenValue(IMAGE_SETTING_OPEN_VALUE)
                  }
                >
                  <Icon name="image" primaryColor={iconColor()} size="small" />
                  <Text type="caption" color={textColor()} content="Images" />
                  {isActive(Steps.EditPicture)}
                </div>
              </>
            )}
            {isSaveLayoutVisible && currentLayout?.layoutId !== undefined && (
              <div
                id="save_layout_btn"
                className={`${styles.menu}`}
                onClick={async () => {
                  if (currentLayout.layoutId !== undefined) {
                    setIsSaving(true)
                    try {
                      await updateLayout(currentLayout.layoutId)
                      setToasterNotification(backPanelToasterNotification)
                    } catch (error) {
                      console.error(error)
                    } finally {
                      setIsSaving(false)
                    }
                  }
                }}
              >
                <Icon name="save" primaryColor={iconColor()} size="small" />
                <Text
                  color={textColor()}
                  type="caption"
                  content="save layout"
                  whiteSpace="nowrap"
                />
                <Div width="6px" height="6px" />
              </div>
            )}
            <div
              className={`${styles.menu}`}
              onClick={() => handleSave().catch(error => console.error(error))}
            >
              <Icon name="save" primaryColor={iconColor()} size="small" />
              <Text type="caption" color={textColor()} content="save" />
              <Div width="6px" height="6px" />
            </div>
          </div>
        ) : (
          <Flex justifyContent="center" alignItems="center">
            <Flex
              justifyContent="center"
              alignItems="center"
              outset={isMobile ? { right: 'x_5' } : undefined}
              onClick={() =>
                setShowLockedPanelInfoDialog(!showLockedPanelInfoDialog)
              }
              flexDirection={isMobile ? 'row' : 'column'}
              cursor="pointer"
            >
              <Icon name="lock" primaryColor={iconColor()} size="small" />
              {isMobile ? (
                <>
                  <Text
                    type="body"
                    color={textColor()}
                    content="Design Panel Locked"
                    outset={{ left: 'x_5' }}
                    isItalic={true}
                    weight="thin"
                  />

                  <Icon
                    name="information"
                    primaryColor={iconColor()}
                    size="xSmall"
                  />
                </>
              ) : (
                <Text
                  type="footnote"
                  color={textColor()}
                  content="Locked"
                  isItalic={true}
                  weight="semiBold"
                  outset={{ top: 'x_5' }}
                />
              )}
            </Flex>
          </Flex>
        )}
      </nav>
      <div />
      {showConfirmVisible && (
        <ConfirmDialog
          title="You have made unsaved changes to this card"
          description="This card will be available in your Account under 'Drafted Cards'. Any unsaved changes will be lost."
          primaryAction="decline"
          isOpen={showConfirmVisible}
          accept={{
            title: 'Continue without saving',
            onClick: onClose,
          }}
          decline={{
            title: 'Cancel and stay on this page.',
            onClick: () => setShowConfirmVisible(!showConfirmVisible),
          }}
          onClose={() => setShowConfirmVisible(!showConfirmVisible)}
        />
      )}
      <Dialog
        isOpen={showLockedPanelInfoDialog}
        onClose={() => setShowLockedPanelInfoDialog(!showLockedPanelInfoDialog)}
      >
        <Flex alignItems="center" flexDirection="column" style={{ gap: 24 }}>
          <Icon name="lock" primaryColor={'primaryHeadingText'} size="large" />
          <Text type="subtitle" weight="bold" alignment="center">
            Why is the design panel locked?
          </Text>
          <Text type="body" alignment="center">
            Our professionally designed catalog cards are locked as adding text,
            images, or elements are not permitted at this time. Please click the
            arrow or swipe to navigate to another panel to begin editing your
            card.
          </Text>
        </Flex>
      </Dialog>
    </div>
  )
}

export default EditorToolBar
